import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { Category } from '@Types/product/Category';
import { Product } from '@Types/product/Product';
import { Facet } from '@Types/result/Facet';
import { Term } from '@Types/result/Term';
import { TermFacet } from '@Types/result/TermFacet';
import Spinner from 'components/commercetools-ui/spinner';
import FilterIcon from 'components/icons/filter';
import Breadcrumb from 'components/revelo-ui/breadcrumb';
import Filters from 'components/revelo-ui/filters';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';
import { useFilter } from '../../../../frontastic';
import Markdown from '../../../revelo-ui/content/markdown';
import Pagination from '../../../revelo-ui/pagination';
import { FilterUtils } from '../../../revelo-ui/utils/FilterUtils';
import List from './list';

export interface Props {
  products: Product[];
  previousCursor: string;
  nextCursor: string;
  category: Category;
  facets: Facet[];
  totalProducts: number;
  visibleFilters?: string[];
  noProductsMarkdown?: string;
  categoryTitle?: string;
  debounceDelay?: number;
}

export default function ProductList({
  products,
  totalProducts,
  previousCursor,
  nextCursor,
  category,
  facets,
  visibleFilters = [],
  noProductsMarkdown = '',
  categoryTitle,
  debounceDelay,
}: Props) {
  const [isDesktopSize, width] = useMediaQuery(desktop);
  const router = useRouter();
  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const { setFilter, resetFilter, setDebounceDelay, submitFilter, isUpdating } = useFilter();

  //i18n messages
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const toggleFiltering = () => {
    if (isDesktopSize) return;

    if (isFiltering) {
      submitFilter();
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    setIsFiltering(!isFiltering);
  };
  const closeFilters = () => setIsFiltering(false);

  useEffect(() => {
    const hasFacetsInQuery = Object.keys(router.query).some((key) => key.includes('facets'));

    if (!hasFacetsInQuery) {
      resetFilter();
    }

    setDebounceDelay(debounceDelay ?? 0);
  }, []);

  const filteredFacets = facets.filter(({ type }) => type === 'term') as TermFacet[];
  filteredFacets.forEach((facet) => {
    const isBooleanFacet =
      (facet.terms.length === 2 &&
        facet.terms.some(({ key }) => key === 'TRUE') &&
        facet.terms.some(({ key }) => key === 'FALSE')) ||
      (facet.terms.length === 1 &&
        (facet.terms.some(({ key }) => key === 'TRUE') || facet.terms.some(({ key }) => key === 'FALSE')));

    if (isBooleanFacet) {
      facet.terms.forEach((term) => {
        // override term label with translation for use in filters and active filters
        term.label = formatProductMessage({
          id: `filter.boolean.${term.key.toLowerCase()}`,
          defaultMessage: term.label,
        });
      });
    } else {
      FilterUtils.sortFacetTerms(facet);
    }
  });

  const activeTermFacets = filteredFacets
    .filter(({ selected }) => selected)
    .map<TermFacet>((facet) => ({
      ...facet,
      terms: facet.terms.filter(({ selected }) => selected),
    }));

  const disableFacetTerm = (facet: TermFacet, term: Term) => {
    setFilter((filter) => ({
      ...filter,
      termFilteringParams: filter.termFilteringParams.filter(
        ({ key, value }) => !(key.includes(`facets[${facet.key}][terms]`) && value === term.label),
      ),
      shouldSubmit: true,
    }));
  };

  const clearAll = (
    <NextLink href={router?.asPath.split('?')[0] || ''} key="clear-all">
      <a className="shrink-0 underline" onClick={resetFilter}>
        {formatProductMessage({ id: 'clear', defaultMessage: 'Clear all' })}
      </a>
    </NextLink>
  );

  const activeFilters = activeTermFacets.length > 0 && (
    <div className="no-scrollbar flex items-center gap-3 overflow-x-scroll">
      {!isDesktopSize && clearAll}
      {activeTermFacets.map((facet) =>
        facet.terms.map((term) => (
          <div
            className="inline-flex h-8 shrink-0 cursor-pointer items-center rounded border border-primary-400 px-2.5"
            key={`${facet.key}.${term.key}`}
            onClick={() => disableFacetTerm(facet, term)}
          >
            <span className="text-xs">{term.label}</span>
            <XIcon className="inline-block h-2.5 w-2.5 pl-1 text-primary-400" />
          </div>
        )),
      )}
      {isDesktopSize && clearAll}
    </div>
  );

  return (
    <div className="mt-4 mb-3 border-b border-gray-200 px-1 sm:px-3 lg:px-6">
      {category?.name && (
        <Head>
          <title>{formatMessage({ id: 'meta.plp.title', values: { category: category?.name } })}</title>
          <meta
            name="description"
            content={formatMessage({ id: 'meta.plp.description', values: { category: category?.name } })}
          />
        </Head>
      )}

      {category && <Breadcrumb breadcrumbs={category.breadcrumbs} />}

      {(categoryTitle || category?.name) && (
        <h1 className="mt-4 text-lg font-bold leading-6 -tracking-[.01em] text-gray-900">
          {categoryTitle || category?.name}
        </h1>
      )}

      {width === 0 ? (
        <div className="flex h-screen items-stretch justify-center py-20 px-12">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="sticky top-0 z-10 mt-4 gap-16 bg-white lg:relative lg:grid lg:grid-cols-4">
            {isDesktopSize || isFiltering ? (
              <div className="relative flex w-full justify-between py-2">
                <h6 className="text-base text-neutral-700 dark:text-light-100" onClick={toggleFiltering}>
                  {!isDesktopSize && <ChevronLeftIcon className="mr-2.5 inline h-5" />}
                  {formatProductMessage({ id: 'filterOnly', defaultMessage: 'Filter' })}
                </h6>
              </div>
            ) : (
              <button onClick={toggleFiltering} className="flex w-full justify-between py-2.5">
                <div className="flex gap-1">
                  <FilterIcon className="h-6 w-5 fill-neutral-700 dark:fill-light-100" />
                  <h6 className="text-base font-bold text-neutral-700 dark:text-light-100">
                    {formatProductMessage({ id: 'sortAndFilter', defaultMessage: 'Sort & Filter' })}
                  </h6>
                </div>
                <h6 className="col-span-2 block text-right dark:text-light-100 lg:hidden">
                  {`${products?.length} ${formatProductMessage({
                    id: 'items',
                    defaultMessage: 'Items',
                  })} ${totalProducts}`}
                </h6>
              </button>
            )}
          </div>
          <div className="gap-16 lg:grid lg:grid-cols-4">
            <h6 className="col-span-2 hidden text-right dark:text-light-100 lg:block">
              {`${products.length} ${formatProductMessage({ id: 'items', defaultMessage: 'Items' })} ${totalProducts}`}
            </h6>
            {!isDesktopSize && activeFilters}
          </div>

          {isDesktopSize || isFiltering ? (
            <div className="mt-2 grid gap-16 lg:grid-cols-4">
              <div className="">
                <Filters
                  facets={facets}
                  products={products}
                  totalProducts={totalProducts}
                  visibleFilters={visibleFilters}
                  closeFilters={closeFilters}
                />
              </div>
              <div className="lg:col-span-3">
                {isDesktopSize && activeFilters}
                {isUpdating ? (
                  <div className="flex h-screen items-stretch justify-center py-20 px-12">
                    <Spinner />
                  </div>
                ) : totalProducts > 0 && products.length > 0 ? (
                  <>
                    <List products={products} />
                    <Pagination previousCursor={previousCursor} nextCursor={nextCursor} />
                  </>
                ) : (
                  <Markdown className="prose mb-4 text-sm prose-a:text-accent-600" text={noProductsMarkdown} />
                )}
              </div>
            </div>
          ) : totalProducts === 0 ? (
            <Markdown className="prose mb-4 text-sm prose-a:text-accent-600" text={noProductsMarkdown} />
          ) : (
            <>
              <List products={products} />
              <Pagination previousCursor={previousCursor} nextCursor={nextCursor} />
            </>
          )}
        </>
      )}
    </div>
  );
}
